let sidebarButton = document.querySelector('#sidebar-button')
let navbar = document.querySelector('#navigation-bar')
let sidebarCloseButton = document.querySelector('#sidebar-close-button')


// sidebar button for mobile
sidebarButton.addEventListener('click', function (el) {
    let modalBackDrop = document.createElement('div')
    modalBackDrop.setAttribute('id', 'modal__backdrop')
    modalBackDrop.classList.add('modal__backdrop', 'z-40')
    document.body.append(modalBackDrop)
    document.body.classList.add('overflow-hidden')

    navbar.classList.add('top-0')
    navbar.classList.remove('top-[-100%]')

})


// sidebar close button
sidebarCloseButton.addEventListener('click', function (el) {

    if (el.target.closest('#sidebar-close-button')) {
        let modalBackdrop = document.querySelector('#modal__backdrop')

        // if modal backdrop exist
        if (modalBackdrop) {
            modalBackdrop.remove()
        }

        navbar.classList.add('top-[-100%]')
        navbar.classList.remove('top-0')
        document.body.classList.remove('overflow-hidden')
    }
})


window.addEventListener('scroll', function () {
    let scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

    let header = document.querySelector('#header')

    if (scrollPosition >= 80) {
        header.classList.add('bg-white')
    } else {
        header.classList.remove('bg-white')
    }

})


adjustActiveNav()


function adjustActiveNav() {
    let navbarMenus = document.querySelectorAll('.nav__menu-item')


    // clear all selected menu
    for (const navbarMenu of navbarMenus) {

        let currentUrl = window.location.href
        let urlWithoutParams = currentUrl.replace(/\?.*/, "").replace(/\/+$/, "")

        if (urlWithoutParams === navbarMenu.querySelector('a').getAttribute('href')) {
            navbarMenu.classList.add('nav__menu-item--active')
        } else {
            navbarMenu.classList.remove('nav__menu-item--active')
        }

    }


}
